var timer = null;

// Resize functions
$(window).resize(function () {
    if (timer) {
        window.clearTimeout(timer);
    }
    timer = window.setTimeout(function () {
        if ($('#providers-map').length) {
            fitLocationMapBounds();
        }
    }, 50);
});